import styles from 'components/TrialCard/components/StudyTag/StudyTag.module.scss';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StudyTag = ({
  className,
  iconClassName,
  studyInfoClassName,
  Icon,
  name,
}) => {
  return (
    <div className={className}>
      {Icon && (
        <IconWrapper
          Icon={Icon}
          className={classNames(iconClassName, styles.icon)}
        />
      )}
      <div className={studyInfoClassName}>{name || null} </div>
    </div>
  );
};

StudyTag.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  studyInfoClassName: PropTypes.string,
  name: PropTypes.string,
  Icon: PropTypes.func,
};

StudyTag.defaultProps = {
  className: '',
  iconClassName: '',
  studyInfoClassName: '',
  name: null,
  Icon: null,
};

export default StudyTag;
